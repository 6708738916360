import moment from "moment"
import { CSSProperties, FC, memo } from "react"

const FirstPageHeader: FC<{
  termDesc: string
  facilityTimeZone: string
}> = ({ termDesc, facilityTimeZone }) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }
  
  const invoiceHeaderParagraphyStyle: CSSProperties = {
    margin: 0,
    padding: 0,
    fontSize: '12px',
    marginBottom: 4
  }

  const invoiceDividerStyle: CSSProperties = {
    width: '100%', 
    height: 1, 
    backgroundColor: '#2b78e4',
    marginTop: 10,
    marginBottom: 10,
  }

  return (
    <html>
      <body style={bodyStyle}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              width: 150,
            }}
            src="/img/bravoCareLogo_desktop.png"
            alt="BravoCare Logo"
          />

          <div style={{ fontSize: '16px', flex: 1, display: 'flex', textAlign: 'center', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            Statement
          </div>

          <div
            style={{
              width: 150,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <div>
              <div style={invoiceHeaderParagraphyStyle}>
                Date: {moment().tz(facilityTimeZone).format('MM/DD/YYYY')}
              </div>
              <div style={invoiceHeaderParagraphyStyle}>Terms: {termDesc}</div>
            </div>
          </div>
        </div>
        
        <div style={invoiceDividerStyle}></div>
      </body>
    </html>
  )
}

export default memo(FirstPageHeader)
